<template>
  <div>
    <div class="ptyfbj">
      <div class="container">
        <h4>平台研发背景</h4>
        <div class="list">
          <div class="box">
            <img src="@/assets/img/ptyfbg01.png" alt="">
            <p>国家安全生产信息化“十三五”规划</p>
            <span>国家对企业的生产安全要求越来越高，对于安全信息化建设做了明确的要求：云服务、大数据、移动互联等技术将快速应用于不同工业企业的安全管理，并印发全国安全生产“一张图地方建设指导意见书的通知”，要求按照全国安全生产信息化“一盘棋、一张网、一张图、一张表”的总体目标。</span>
          </div>
          <div class="box">
            <img src="@/assets/img/ptyfbg02.png" alt="">
            <p>2020年全国安全生产电话会议4月10日在北京召开</p>
            <span>强调了加强安全生产监管，分区分类加强执法力度，强化企业主体责任落实，牢守安全生产底线，坚决遏制事故的发生，切实维护从业人员的生命财产安全，并决定于2020年4月启动全国安全生产专项整治三年行动。</span>
          </div>
          <div class="box">
            <img src="@/assets/img/ptyfbg03.png" alt="">
            <p>全国安全生产专项整治三年行动计划</p>
            <span>明确了重点对“两个专题、九个行业领域”开展专项整治，其中“落实企业安全生产主体责任专题”明确要求提高企业安全管理能力，建立完善的隐患排查体系，实现安全生产现场管理、操作行为、设备设施和作业环境的规范化，推进企业安全生产由被动接受监管向主动加强管理转变，提升企业本质安全水平，遏制事故发生。</span>
          </div>
        </div>
      </div>
    </div>
    <img style="width: 100%;" src="@/assets/img/qymldtz.png" alt="">
    <div class="xxkf">
      <h3>平台简介</h3>
      <div class="container display-flex">
        <img src="@/assets/img/xxkf01.png" alt="">
        <div class="content">
          <h4>智慧安全管理平台</h4>
          <p>追求在提升工业企业安全管理工作效率的基础上，提供安全环保信息化管理综合解决方案，通过将生产现场的实际情况，安全管理体系和信息化技术的结合，最终实现智慧安全管理。</p>
          <p>在企业的安全管理中，监控视频，巡检纪录，违章处理，整改情况等数据，都必须使用特殊化处理分析才能形成有规律、可预测的信息服务能力。通过对安全生产中产生的数据的利用，对安全生产周期性、关联性等特征进行分析，最终去实现来源可查，去向可追，责任可究，规律可循的安全管理。</p>
          <div class="category">
            <span><img src="@/assets/img/icon-zygxzd.png" alt="">专业高效指导</span>
            <span><img src="@/assets/img/icon-sgyc.png" alt="">事故预测</span>
            <span><img src="@/assets/img/icon-hybps.png" alt="">行业白皮书</span>
          </div>
        </div>
      </div>
    </div>
    <img style="width: 100%;" src="@/assets/img/cplb.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'AQSCXXH',
  components: {},
  props: {},
  data () {
    return {}
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {

  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
  .ptyfbj {
    padding-bottom: 60px;
    h4 {
      text-align: center;
      font-size: 32px;
      padding: 40px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .box {
        width: 32%;
        height: 460px;
        background: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(205, 205, 205, 0.5);
        display: flex;
        flex-direction: column;
        img {
          height: 250px;
        }
        p {
          font-size: 16px;
          color: #000000;
          margin-top: 25px;
          padding: 0 30px;
        }
        span {
          font-size: 14px;
          color: #666666;
          margin-top: 15px;
          padding: 0 30px;
        }
      }
    }
  }
  .xxkf {
    h3 {
      font-weight: 500;
      font-size: 32px;
      color: #FFFFFF;
      text-align: center;
      padding-top: 60px;
      margin-bottom: 40px;
    }
    h4 {
      font-size: 22px;
    }
    background-image: url('~@/assets/img/ptjj-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 640px;
    img {
      height: 438px;
    }
    .content {
      width: 100%;
      background-color: #fff;
      height: 438px;
      padding: 40px 30px;
      p {
        padding: 30px 0;
        color: #666666;
      }
      .category {
        font-size: 18px;
        margin-top: 30px;
        span {
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          padding: 0 20px;
          border-left: 1px solid #D8D8D8;
          &:nth-child(1) {
            border-left: none;
            padding-left: 0;
          }
        }
      }
    }
  }
</style>
